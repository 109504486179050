@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading {
  font-family: "Playfair", serif;
  font-size: 35.88px;
}
.serif {
  font-family: "Playfair", serif;
}
.sans-serif {
  font-family: "Montserrat", sans-serif;
}
p {
  font-weight: light;
  font-size: 19px;
}
.resetp {
  font-weight: inherit;
  font-size: inherit;
}
.showlist > ul {
  list-style: disc;
  padding-left: 1.45rem;
}
.bolderb b {
  /* font-weight: 900; */
}

/* less than lg */
@media not all and (min-width: 1024px) {
  .heading {
    font-size: 28px;
  }
  /* p {
    font-size: 17px;
  } */
}


@keyframes custom-fadein {
  0%   {
    background: black;
    opacity: 100%;
    height: 100%;
  }
  99% {
    opacity: 0%;
    height: 100%;
  }
  100% {
    opacity: 0%;
    height: 0;
  }
}

.blackfade {
    position: absolute;
    top:0;
    left: 0;
    pointer-events: none;

    width: 100%;
    z-index: 9999;
    animation: normal custom-fadein 2s ;
    /* animation-name: custom-fadein ; */
    animation-duration: 2.5s;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0F1219;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #171d2a;
}


/* width */
.SMALL-SCROLLBAR::-webkit-scrollbar {
  width: 3px;
  height: 3PX;
}

/* Track */
.SMALL-SCROLLBAR::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.SMALL-SCROLLBAR::-webkit-scrollbar-thumb {
  background: #0F1219;
}

/* Handle on hover */
.SMALL-SCROLLBAR::-webkit-scrollbar-thumb:hover {
  background: #171d2a;
}

